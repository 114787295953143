import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-reset-user-password',
  templateUrl: './reset-user-password.component.html',
  styleUrls: ['./reset-user-password.component.scss']
})
export class ResetUserPasswordComponent implements OnInit {

  // Form code
  resetForm: FormGroup;
  notLogged = false;
  loading = false;
  errMess: string;
  @ViewChild('rform', { static: false }) resetFormDirective;
  response: boolean;

  formErrors = {
    'email': '',

  };

  validationMessages = {
    'email': {
      'required': 'Champ obligatoire.',
      'pattern': 'Email non valide.'
    }
  };


  constructor(private fb: FormBuilder, private userService: UserService,
    private router: Router) {
    this.createForm();
  }



  ngOnInit(): void {

  }

  createForm() {
    this.resetForm = this.fb.group({
      email: ['', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ]
      ]
    });
    this.resetForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages
  }

  onValueChanged(data?: any) {
    if (!this.resetForm) {
      return;
    }
    const form = this.resetForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    this.loading = true;
    this.userService.resetUserPassword(this.resetForm.get('email').value)
      .subscribe(resp => {
        this.loading = false;
        console.log(resp)
        this.response = resp;

        this.resetForm.reset({
          password: '',
        });
        this.resetFormDirective.resetForm();

      },
        err => {
          this.loading = false;
          this.notLogged = true;

          this.resetForm.reset({
            password: '',
          });
          this.resetFormDirective.resetForm();

        });
  }


}
