import { DecimalPipe } from '@angular/common';
import { Component, OnInit, PipeTransform, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Agent } from '../../entities/agent';
import { AgentService } from '../../services/agent/agent.service';
import { LoginService } from '../../services/login/login.service';



@Component({
  selector: 'app-list-agent',
  templateUrl: './list-agent.component.html',
  styleUrls: ['./list-agent.component.scss'],
  providers: [DecimalPipe]
})
export class ListAgentComponent implements OnInit {

  agents: Agent[] | null;
  allAgents: Agent[] | null;

  isDeleted: boolean = false;
  isEdited: boolean = false;
  errMess: string;
  noAgentMess: string;
  @ViewChild('lgModal', { static: false }) lgModal?: ModalDirective;
  modalRef?: BsModalRef;
  @ViewChild('lgeditModal', { static: false }) lgeditModal?: ModalDirective;
  @ViewChild('sform', { static: false }) searchFormDirective;
  editmodalRef?: BsModalRef;
  agentId: string;
  state: boolean;
  id: string;

  page = 1;
  pages = 10;
  pageCollectionSize: number;
  searchTerm: string;

  searchForm: FormGroup;



  formErrors = {
    'keyword': ''
  };

  validationMessages = {
    'keyword': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.'
    }
  };




  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };



  constructor(
    private fb: FormBuilder,
    private agentService: AgentService,
    private loginService: LoginService,
    private modalService: BsModalService
  ) {
    this.createForm();
    // this.refreshAgents();
    this.searchForm.get("keyword").valueChanges.subscribe(data => {
      this.searchTerm = data;
    });
  }


  createForm() {
    this.searchForm = this.fb.group({

      keyword: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9 àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ]
    });
    this.searchForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages
    this.searchForm.get('keyword').valueChanges.subscribe(data => {
      this.searchTerm = data;
    });
  }

  onValueChanged(data?: any) {
    if (!this.searchForm) {
      return;
    }
    const form = this.searchForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }


  ngOnInit(): void {
    this.id = this.loginService.id;
    this.getAllAgentByCreatorUser(this.id);

  }

  getAllAgentByCreatorUser(id: string) {
    this.agentService.getAgentsByCreatorUser(id).subscribe(response => {
      if (response['status'] === 1) {
        this.pageCollectionSize = response['resultat'].length;
        this.agents = response['resultat'];
        console.log("Agent by user::", this.agents);
        this.allAgents = this.agents;
      }else{
        this.noAgentMess = response['msg'];
      }
    }, err => {
      console.error(err);
      this.errMess = err;

    });
  }

  search(value: string): void {
    this.agents = this.allAgents.filter((val) => val.idagent.toLowerCase().includes(value));
    this.pageCollectionSize = this.agents.length;
  }

  deleteAgent(id) {
    this.agentService.deleteAgent(id).subscribe(resp => {
      this.isDeleted = true;
      if (this.isDeleted) {
        // this.isDeleted = true;
        this.getAllAgentByCreatorUser(this.id);
      }
    }, err => {
      this.errMess = err;
    });
  }

  isUser(): boolean {
    return this.loginService.isUser();
  }

  isAdmin(): boolean {
    return this.loginService.isAdmin();
  }

  isManager(): boolean {
    return this.loginService.isManager();
  }

  isBackOffice(): boolean {
    return this.loginService.isBackOffice();
  }

  openEditModal(template: TemplateRef<any>, id) {
    this.editmodalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.agentId = id;
  }

  setId(id: string) {
    this.agentId = id;
    console.log('ID: ', this.agentId);

  }


  hideEditModal() {
    this.lgeditModal.hide();
    this.getAllAgentByCreatorUser(this.id);
  }

  openModal(template: TemplateRef<any>, id: string) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.agentId = id;
  }

  confirm(): void {

    console.log("Agent id: ", this.agentId);
    this.isDeleted = true;
    this.modalRef?.hide();

    this.deleteAgent(this.agentId);

  }

  decline(): void {
    this.isDeleted = false;
    this.modalRef?.hide();
  }

  hideModal() {
    this.lgModal.hide();
    this.getAllAgentByCreatorUser(this.id);
  }

  disActivate(id) {

    this.agentService.disactivateAgent(id).subscribe(state => {
      if (state) {
        this.getAllAgentByCreatorUser(this.id);
      };
    }, err => {
      this.errMess = err;
    });
  }

  activate(id) {
    this.agentService.activateAgent(id).subscribe(state => {
      if (state) {
        this.getAllAgentByCreatorUser(this.id);
      }
    }, err => {
      this.errMess = err;
    });
  }

}
