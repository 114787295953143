<!-- research fields -->
<div class="login-container">
    <div class="alert alert-danger" role="alert" *ngIf="notLogged">
        Nom d'utilisateur ou mot de passe incorrect.
    </div>
    <div class="icon">
        <img width="170" src="assets/images/logo1.png" alt="Sama by UBA">
    </div>
    <div class="card">
        <div class="card-body">
            <form novalidate [formGroup]="loginForm" #lform="ngForm" (ngSubmit)="onLogin()">
                <div class="form-row">

                    <div class="col-sm-12 my-1">
                        <label for="inlineFormInputGroupUsername">Nom d'utilisateur</label>
                        <div class="input-group">

                            <input id="inlineFormInputGroupUsername" type="text" class="form-control"
                                formControlName="idUser" autocomplete="off" pattern />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.idUser">
                            {{ formErrors.idUser }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <label for="inlineFormInputGroupPassword">Mot de passe</label>
                        <div class="input-group">

                            <input id="inlineFormInputGroupPassword" type="password" class="form-control"
                                formControlName="password" autocomplete="new-password" required />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.password">
                            {{ formErrors.password }}
                        </small>
                    </div>


                    <div class="col-auto my-1">
                        <label for="inlineFormInputGroupBtn" style="color: white;"></label>
                        <div class="input-group">

                            <div class="my-spinner" *ngIf="loading">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>

                            </div>
                            <button type="submit" class="btn btn-valid" [disabled]="loginForm.invalid || loading"
                                id="inlineFormInputGroupBtn">

                                Se connecter
                            </button>
                        </div>
                    </div>

                    <!-- <div class="signin">
                        Pas de compte?
                        <div class="col-sm my-1">
                            <a href="/add-user"></a>S'inscrire</a>
                        </div>
                    </div> -->

                </div>
            </form>
        </div>
    </div>

</div>