import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../entities/user';
import { UserDto } from '../../entities/userDto';
import { LoginService } from '../../services/login/login.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-details-user',
  templateUrl: './details-user.component.html',
  styleUrls: ['./details-user.component.scss']
})
export class DetailsUserComponent implements OnInit {
  user: UserDto;

  constructor(private userService: UserService, private route: ActivatedRoute, private authService: LoginService) { }

  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('idUser');
    this.userService.getUserById(id).subscribe(user => {
      this.user = this.userToUserDto(user);
    });
  }

  userToUserDto(user: User): UserDto {
    const dto: UserDto = new UserDto();

    dto.idUser = user.idUser;
    dto.lastName = user.lastName;
    dto.firstName = user.firstName;
    dto.email = user.email;
    dto.phone = user.phone;
    dto.dateHu = user.dateHu;

    switch (user.idRole) {
      case "1":
        dto.role = "ADMIN"
        break;
      case "2":
        dto.role = "MANAGER"
        break;
      case "3":
        dto.role = "BACKOFFICE"
        break;

      default:
        break;
    }

    return dto;
  }

  isUser() {
    return this.authService.isUser();
  }

  isManager() {
    return this.authService.isManager();
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  isBackOffice() {
    return this.authService.isBackOffice();
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }


}
