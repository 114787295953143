import { Pipe, PipeTransform } from '@angular/core';
import { Agent } from '../components/entities/agent';


@Pipe({ name: 'listFilterAgent' })
export class ListFilterPipeAgent implements PipeTransform {

    transform(list: Agent[], filterText: string): any {
        return list ? list.filter(item => item.idagent.search(new RegExp(filterText, 'i')) > -1) : [] , list ? list.filter(item => item.phone.search(new RegExp(filterText, 'i')) > -1) : [];
    }
}