<div class="container-fluid">



    <div class="alert alert-success" role="alert" *ngIf="isDeleted">
        Agent supprimé avec succès.
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="agents && errMess">
        {{ errMess.message }}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="noAgentMess">
        {{ noAgentMess }}
    </div>

    <h1>Liste des agents</h1>

    <div *ngIf="(isAdmin() || isManager())" class="btn-group ml-auto" style="float: right;" role="group"
        aria-label="Basic example">

        <button class="btn btn-ajout" routerLink="/add-agent">Ajouter agent</button>

    </div>

    <div class="my-spinner" *ngIf="!agents && !noAgentMess">
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h4>Chargement...</h4>
    </div>

    <ng-container *ngIf="agents && (isAdmin() || isManager() || isBackOffice())">
        <div class="table-responsive-sm">
            <form class="form-inline my-2 my-lg-0" novalidate [formGroup]="searchForm" #sform="ngForm"
                (ngSubmit)="onSearchSubmit()">
                <div class="row">
                    <div class="col-sm my-1">
                        <input class="form-control mr-sm-2" type="search" placeholder="Recherche" aria-label="Search"
                            formControlName="keyword" autocomplete="off" pattern required>

                    </div>

                </div>
            </form>
            <!-- <ng-container *ngIf="isSearch; else allTemplate"> -->
            <table class="table table-bordered table-hover">
                <thead class="my-thead">
                    <tr>
                        <th class="col-sm-1 text-center align-middle" scope="col">#</th>
                        <th class="text-center align-middle" scope="col">Identifiant</th>
                        <th class="text-center align-middle" scope="col">Nom</th>
                        <th class="text-center align-middle" scope="col">Prénom</th>
                        <th class="text-center align-middle" scope="col">Téléphone</th>
                        <th class="text-center align-middle" scope="col">Type de la pièce d'identité</th>
                        <th class="text-center align-middle" scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody
                    *ngFor="let agent of agents | listFilterAgent: searchTerm | slice: (page-1) * pages : page * pages; let i=index">
                    <tr class="table-rows" [ngClass]="{'approuved': agent.isActivated === '1', 'created': agent.creer === '1'}">
                        <td class="text-center align-middle" scope="row">{{ (page -1) * pages + i + 1 }}</td>
                        <td class="text-center align-middle">{{agent.idagent}}</td>
                        <td class="text-center align-middle">{{agent.firstName}}</td>
                        <td class="text-center align-middle">{{agent.lastName}}</td>
                        <td class="text-center align-middle">{{agent.phone }}</td>
                        <td class="text-center align-middle">{{agent.cardDeliv}}</td>
                        
                        <td class="text-center align-middle">
                            <div class="btn-group ml-auto" role="group" aria-label="Basic example">
                                <a class="btn btn-voir" [routerLink]="[ '/agent', agent.idagent ]">Voir</a>
                                <a class="btn btn-modif" [routerLink]="[ '/edit-agent', agent.idagent ]"
                                    *ngIf="isManager() || isAdmin()">Modifier</a>
                                <a class="btn btn-supp" (click)="openModal(template, agent.idagent)"
                                    *ngIf="isAdmin()">Supprimer</a>

                            </div>
                        </td>
                    </tr>
                </tbody>
                <div class="row">
                    <div class="col-xs-12 col-12">

                        <ngb-pagination class="pagination-color" [collectionSize]="pageCollectionSize" [(page)]="page"
                            [pageSize]="pages" [maxSize]="10" [boundaryLinks]="true">
                        </ngb-pagination>

                    </div>
                </div>
            </table>

        </div>
    </ng-container>

    <ng-template #template>
        <div class="modal-body text-center">
            <p>Voulez-vous vraiment supprimer ce compte agent ?</p>
            <div class="btn">
                <button type="button" class="btn btn-supp" (click)="confirm()">Oui</button>
                <button type="button" class="btn btn-non" (click)="decline()">Non</button>
            </div>

        </div>
    </ng-template>

</div>