import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProcessHttpMsgService {

  constructor() { }

  public handleError(error: HttpErrorResponse | any) {

    if (error.error instanceof ErrorEvent) {
      return throwError(error.error.message);
    }
    else {
      return throwError(error.error);
    }


  }
}
