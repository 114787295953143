<div class="container-fluid">

    <div class="card" *ngIf="user">
        <div class="card-header">
            <h2>Détail de {{ user.lastName}} {{ user.firstName}}</h2>
        </div>


        <div class="card-title" style="margin: 10px;">


            <div class="btn-group ml-auto" style="float: right;" role="group" aria-label="Basic example">

                <button *ngIf="isAdmin()" class="btn btn-modif" [routerLink]="['/edit-user', user.idUser ]">Modifier</button>
                <button class="btn btn-list" routerLink="/user-list">Retour à la liste</button>

            </div>
        </div>
        <div class="card-body">
            <div class="row row-contenu">
                <div class="info-text">
                    <table class="table table-bordered">

                        <tbody>
                            <tr>
                                <td class="val">Nom</td>
                                <td>{{ user.lastName}}</td>
                            </tr>
                            <tr>
                                <td class="val">Prénom</td>
                                <td>{{ user.firstName}}</td>
                            </tr>
                            <tr>
                                <td class="val">Adresse email</td>
                                <td>{{ user.email}}</td>
                            </tr>
                            <tr>
                                <td class="val">Téléphone</td>
                                <td>{{ user.phone}}</td>
                            </tr>
                            <tr>
                                <td class="val">Date de création</td>
                                <td>{{ user.dateHu}}</td>
                            </tr>
                            <tr>
                                <td class="val">Rôle</td>
                                <td>{{ user.role}}</td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>



        </div>



    </div>


    <div class="my-spinner" *ngIf="!user">
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h4>Chargement...</h4>
    </div>

</div>