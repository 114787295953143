<div class="container">
    <div class="alert alert-danger" role="alert" *ngIf="errMess">
        {{ errMess.message }}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="notErrMess">
        {{ notErrMess }}
    </div>
    <div class="my-spinner" *ngIf="!user">
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h4>Chargement...</h4>
    </div>
    <div class="card" *ngIf="user">
        <div class="card-header">
            <h1>Modifier un utilisateur</h1>
        </div>
        <div class="card-body">
            <form novalidate [formGroup]="userForm" #lform="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-column ">

                    <div class="col-sm my-1">
                        <label for="firstName">Prénom</label>
                        <div class="input-group">
                            <input id="firstName" type="text" class="form-control" formControlName="firstName" autocomplete="off" required pattern/>
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.firstName">
                            {{ formErrors.firstName }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <label for="lastName">Nom</label>
                        <div class="input-group">
                            <input id="lastName" type="text" class="form-control" formControlName="lastName" autocomplete="off" required pattern/>
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.lastName">
                            {{ formErrors.lastName }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <label for="email">Email</label>
                        <div class="input-group">
                            <input id="email" type="email" class="form-control" formControlName="email" autocomplete="off" pattern required/>
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.email">
                            {{ formErrors.email }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <label for="phobe">Téléphone</label>
                        <div class="input-group">
                            <input id="phobe" type="tel" class="form-control" formControlName="phone" autocomplete="off" pattern required/>

                        </div>
                        <small class="errorsForm" *ngIf="formErrors.phone">
                            {{ formErrors.phone }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <div class="form-group">
                            <label for="role">Rôle</label>
                            <select class="form-control" id="role" formControlName="role" required>
                                <option selected></option>
                                <option value="ADMIN">ADMIN</option>
                                <option value="MANAGER">MANAGER</option>
                                <option value="BACKOFFICE">BACK OFFICE</option>
                            </select>
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.role">
                            {{ formErrors.role }}
                        </small>
                    </div>

                    <div class="col-auto my-1">
                        <label for="inlineFormInputGroupBtn" style="color: white;">.</label>
                        <div class="input-group">
                            <div *ngIf="loading">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>

                            </div>
                            <button type="submit" class="btn btn-edit" [disabled]="userForm.invalid" id="inlineFormInputGroupBtn">
                                Modifier
                            </button>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    </div>
</div>