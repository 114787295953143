import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // Form code
  loginForm: FormGroup;
  notLogged = false;
  loading = false;
  errMess: string;
  @ViewChild('lform', { static: false }) loginFormDirective;
  response: any;

  formErrors = {
    'idUser': '',
    'password': '',
  };

  validationMessages = {
    'idUser': {
      'required': 'Champ obligatoire.',
      'pattern': 'Email non valide.'
    },
    'password': {
      'required': 'Champ obligatoire.'
    }
  };


  constructor(private fb: FormBuilder, private authService: LoginService,
    private router: Router) {
    this.createForm();
  }



  ngOnInit(): void {

  }

  createForm() {
    this.loginForm = this.fb.group({
      idUser: ['', [
        Validators.required,
        // Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ]
      ],
      password: ['', [
        Validators.required
      ]
      ]
    });
    this.loginForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages
  }

  onValueChanged(data?: any) {
    if (!this.loginForm) {
      return;
    }
    const form = this.loginForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onLogin() {
    this.loading = true;
    this.authService.login(this.loginForm.value)
      .subscribe(resp => {
        this.loading = false;
        
        this.response = resp;
        // let jwt = resp.headers.get('Authorization');
        let jwt = resp["token"];

        if (jwt != null) {
          this.authService.saveToken(jwt);

          // Get the redirect URL from our auth service
          // If no redirect has been set, use the default
          let redirect = this.authService.redirectUrl ? this.router.parseUrl(this.authService.redirectUrl) : '/home';
          this.router.navigateByUrl(redirect);
        }
      },
        err => {
          this.loading = false;
          this.notLogged = true;
        });
  }


}
