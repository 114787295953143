import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { baseUrl, baseURL } from 'src/app/shared/base-url';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError } from 'rxjs/operators';
import { ProcessHttpMsgService } from '../error/process-http-msg.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  jwt: string;
  id: string;
  username: string;
  firstname: string;
  lastname: string;
  roles: Array<string> = [];
  redirectUrl: string;

  constructor(private http: HttpClient,
    private processHttpMsgService: ProcessHttpMsgService,
    private router: Router) { }


  login(data) {
    return this.http.post(baseUrl + '/user/login', data)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  saveToken(jwt: string) {
    localStorage.setItem('currtokenag', jwt);
    this.jwt = jwt;

    this.loadUserIdentity();
  }

  loadUserIdentity() {
    let jwtHelper = new JwtHelperService();
    let objJWT = jwtHelper.decodeToken(this.jwt);
    this.id = objJWT.id;
    this.username = objJWT.idUser;
    this.firstname = objJWT.firstName;
    this.lastname = objJWT.lastName;
    this.mapRole(objJWT.idRole);
  }

  mapRole(idRole: string) {
    switch (idRole) {
      case "1":
        this.roles = ["ADMIN"]
        break;
      case "2":
        this.roles = ["MANAGER"]
        break;
      case "3":
        this.roles = ["BACKOFFICE"]
        break;

      default:
        break;
    }
  }

  loadToken() {
    this.jwt = localStorage.getItem('currtokenag');
    if (this.jwt != null) {
      this.loadUserIdentity();
    }
  }

  isAdmin() {
    return this.roles.indexOf('ADMIN') >= 0;
  }

  isManager() {
    return this.roles.indexOf('MANAGER') >= 0;
  }

  isBackOffice() {
    return this.roles.indexOf('BACKOFFICE') >= 0;
  }

  isUser() {
    return this.roles.indexOf('USER') >= 0;
  }


  isAuthenticated() {
    return this.roles.length !== 0;
  }

  isTokenExprired(jwt: string): boolean {
    let jwtHelper = new JwtHelperService();
    let time = jwtHelper.getTokenExpirationDate(jwt);
    
    return jwtHelper.isTokenExpired(jwt);
  }



  logOut() {
    localStorage.removeItem('currtokenag');
    this.initCredentials();
  }

  initCredentials() {
    this.jwt = "";
    this.username = "";
    this.roles = [];
  }

}
