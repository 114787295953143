<!-- research fields -->
<div class="login-container">
    <div class="alert alert-danger" role="alert" *ngIf="notLogged">
        Nom d'utilisateur incorrect ou invalide.
    </div>
    <div class="alert alert-success" role="alert" *ngIf="response">
        Un mail vous a été envoyé, vérifier votre boîte email svp!!!.
    </div>
    <div class="icon">
        <img src="assets/images/favicon.png" alt="Sama by UBA">
    </div>
    <div class="card">
        <div class="card-body">
            <form novalidate [formGroup]="resetForm" #rform="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-row ">

                    <div class="col-sm my-1">
                        <label for="inlineFormInputGroupUsername">Nom d'utilisateur</label>
                        <div class="input-group">

                            <input id="inlineFormInputGroupUsername" type="email" class="form-control"
                                formControlName="email" autocomplete="off" pattern />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.email">
                            {{ formErrors.email }}
                        </small>
                    </div>



                    <div class="col-auto my-1">
                        <label for="inlineFormInputGroupBtn" style="color: white;">.</label>
                        <div class="input-group">

                            <div class="my-spinner" *ngIf="loading">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>

                            </div>
                            <button type="submit" class="btn btn-primary" [disabled]="resetForm.invalid || loading"
                                id="inlineFormInputGroupBtn">

                                Envoyer
                            </button>
                        </div>
                    </div>



                </div>
            </form>
        </div>
    </div>

</div>