import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { User } from '../../entities/user';
import { AgentService } from '../../services/agent/agent.service';
import { LoginService } from '../../services/login/login.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {

  users: User[] | null;
  allUsers: User[] | null;
  isDeleted: boolean = false;
  isEdited: boolean = false;
  errMess: string;
  @ViewChild('lgModal', { static: false }) lgModal?: ModalDirective;
  modalRef?: BsModalRef;
  @ViewChild('lgeditModal', { static: false }) lgeditModal?: ModalDirective;
  editmodalRef?: BsModalRef;
  userId: string;
  searchTerm: string;
  page = 1;
  pages = 10;
  pageCollectionSize: number;

  searchForm: FormGroup;
  // isSearch = false;

  formErrors = {
    'keyword': ''
  };

  validationMessages = {
    'keyword': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.'
    }
  };




  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };


  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private loginService: LoginService,
    private modalService: BsModalService
  ) {
    this.createForm();
  }




  createForm() {
    this.searchForm = this.fb.group({

      keyword: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9 àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ]
    });
    this.searchForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages
     this.searchForm.get('keyword').valueChanges.subscribe(data=>{
      this.searchTerm = data;
    });
  }

  onValueChanged(data?: any) {
    if (!this.searchForm) {
      return;
    }
    const form = this.searchForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }


  ngOnInit(): void {
    this.getAllUsers();
  }

  getAllUsers() {
    this.userService.getAllUsers().subscribe(response => {
      this.pageCollectionSize = response['resultat'].length;
      this.users = response['resultat'];
      this.allUsers = this.users;
    
    }, err => {
      this.errMess = err;
    });
  }

  search(value: string): void {
    this.users = this.allUsers.filter((val) => val.idUser.toLowerCase().includes(value));
    this.pageCollectionSize = this.users.length;
  }


  deleteUser(id) {
    this.userService.deleteUser(id).subscribe(resp => {
      this.isDeleted = true;
      if (this.isDeleted) {
        this.isDeleted = true;
        this.getAllUsers()
      }
    }, err => {
      this.errMess = err;
    });
  }

  isUser(): boolean {
    return this.loginService.isUser();
  }

  isAdmin(): boolean {
    return this.loginService.isAdmin();
  }

  isManager(): boolean {
    return this.loginService.isManager();
  }

  isBackOffice(): boolean {
    return this.loginService.isBackOffice();
  }

  openEditModal(template: TemplateRef<any>, id) {
    this.editmodalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.userId = id;
  }

  setId(id: string) {
    this.userId = id;
  }


  hideEditModal() {
    this.lgeditModal.hide();
    this.getAllUsers();
  }

  openModal(template: TemplateRef<any>, id: string) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.userId = id;
  }

  confirm(): void {

    this.isDeleted = true;
    this.modalRef?.hide();

    this.deleteUser(this.userId);
    // this.getAllUsers();
  }

  decline(): void {
    this.isDeleted = false;
    this.modalRef?.hide();
  }

  hideModal() {
    this.lgModal.hide();
    this.getAllUsers();
  }

}
