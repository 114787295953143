import { DatePipe, formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { Agent } from '../../entities/agent';
import { FileDto } from '../../entities/fileDto';
import { AgentService } from '../../services/agent/agent.service';
import { LoginService } from '../../services/login/login.service';


@Component({
  selector: 'app-edit-agent',
  templateUrl: './edit-agent.component.html',
  styleUrls: ['./edit-agent.component.scss']
})
export class EditAgentComponent implements OnInit {

  agent: Agent;
  path: string;
  bufferPath: string;
  editingAgent: Agent;
  formData = new FormData();
  loading: boolean;
  loadingPiecePhoto: boolean;
  loadingRegistreCom: boolean;
  pays: string;
  typeCard: string;
  agentPhotoFile = new FileDto();
  agentRegistreComFile = new FileDto();
  messPiecePhoto: string;
  messRegistreComPhoto: string;
  messPiecePhotoNotValid: string;
  messRegistreComPhotoNotValid: string;

  // Form code
  agentForm: FormGroup;
  notLogged = false;
  errMess: string;
  notErrMess: string;

  @ViewChild('aform', { static: false }) agentFormDirective;
  @Input() editAgentId: string;


  formErrors = {
    'firstname': '',
    'lastname': '',
    'telephone': '',
    'phoneM': '',
    'phoneSm': '',
    'birthday': '',
    'cityOfBirth': '',
    'pays': '',
    'regions': '',
    'cercle': '',
    'ville': '',
    'commune': '',
    'quartier': '',
    'typeCard': '',
    'numberOfCard': '',
    'cardDeliverDate': '',
    'cardExpDate': '',
    'lieuDeliver': '',
    'cardImageGallery': '',
  };

  validationMessages = {
    'firstname': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'lastname': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'telephone': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 8 lettres.'
    },
    'phoneM': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 8 lettres.'
    },
    'phoneSm': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 8 lettres.'
    },
    'birthday': {
      'required': 'Champ obligatoire.',
    },
    'cityOfBirth': {
      'required': 'Champ obligatoire.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'pays': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'regions': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'cercle': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'ville': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'commune': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 1 lettre.'
    },
    'quartier': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 3 lettres.'
    },
    'numberOfCard': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.'
    },
    'typeCard': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'cardDeliverDate': {
      'required': 'Champ obligatoire.'
    },
    'cardExpDate': {
      'required': 'Champ obligatoire.'
    },
    'lieuDeliver': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'cardImageGallery': {
      'required': 'Champ obligatoire.'
    }
  };
  

  constructor(private fb: FormBuilder, private authService: LoginService, private agentService: AgentService,
    private router: Router,
    private route: ActivatedRoute) {

    this.createForm();

    
  }



  ngOnInit(): void {

    this.editAgentId = this.route.snapshot.paramMap.get('idagent');
    this.agentService.getAgentById(this.editAgentId).subscribe(agent => {
      this.editingAgent = agent;

      this.agentPhotoFile.idagent = agent.idagent;
      this.agentRegistreComFile.idagent = agent.idagent;

      this.setFormValue(this.editingAgent);
    }, err => {
      this.errMess = err;
    });

  }

  onFilePhotoIdenChange(event): void {
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.agentPhotoFile.image = base64;
      if (!this.editingAgent) {
        this.messPiecePhotoNotValid = "Renseigner correctement le champ identifiant"
      }
      else{
        this.submitPiecePhoto(this.agentPhotoFile);
      }
    });
    
  }

  onFilePhotoRegistreComChange(event): void {
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.agentRegistreComFile.image = base64;
      if (!this.editingAgent) {
        this.messRegistreComPhotoNotValid = "Renseigner correctement le champ identifiant"
      }
      else{
        this.submitRegisteComPhoto(this.agentRegistreComFile);
      }
    });
  }

  

  convertFile(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }


  setFormValue(agent: Agent) {
    this.agentForm.get('firstname').setValue(agent.firstName);
    this.agentForm.get('lastname').setValue(agent.lastName);
    this.agentForm.get('telephone').setValue(agent.phone);
    this.agentForm.get('phoneM').setValue(agent.phoneM);
    this.agentForm.get('phoneSm').setValue(agent.phoneSm);
    this.agentForm.get('birthday').setValue(agent.birthday);
    this.agentForm.get('cityOfBirth').setValue(agent.cityOfBirth);
    this.agentForm.get('pays').setValue(agent.pays);
    this.agentForm.get('regions').setValue(agent.region);
    this.agentForm.get('cercle').setValue(agent.cercle);
    this.agentForm.get('ville').setValue(agent.ville);
    this.agentForm.get('commune').setValue(agent.commune);
    this.agentForm.get('quartier').setValue(agent.quartier);
    this.agentForm.get('typeCard').setValue(agent.cardDeliv);
    this.agentForm.get('numberOfCard').setValue(agent.numPiece);
    this.agentForm.get('cardDeliverDate').setValue(agent.dateDeliv);
    this.agentForm.get('cardExpDate').setValue(agent.dateExp);
    this.agentForm.get('lieuDeliver').setValue(agent.placeOfDeliv);

    // this.path = agent.images;

  }

  createForm() {
    this.agentForm = this.fb.group({
      firstname: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')

      ]
      ],
      lastname: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      telephone: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^[0-9]*$')
      ]
      ],
      phoneM: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^[0-9]*$')
      ]
      ],
      phoneSm: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[0-9]*$')
      ]
      ],
      birthday: [null, [
        Validators.required,

      ]
      ]
      ,
      cityOfBirth: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ]
      ,
      pays: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      regions: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      cercle: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      ville: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      commune: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern('^[a-zA-Z0-9 àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      quartier: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z0-9 àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      numberOfCard: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9/]*$')
      ]
      ]
      ,
      typeCard: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ\']*$')
      ]
      ],
      cardDeliverDate: [null, [
        Validators.required
      ]
      ]
      ,
      cardExpDate: [null, [
        Validators.required
      ]
      ]
      ,
      lieuDeliver: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      cardImagePhotoIden: [null, [
        Validators.required
      ]
      ],
      cardImageRegistreCom: [null]
    },{validator: this.dateLessThan('cardDeliverDate', 'cardExpDate')});
    this.agentForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages

    this.agentForm.get('pays').valueChanges.subscribe(data=>{
      this.formData.append('pays', data);
    });

    this.agentForm.get('typeCard').valueChanges.subscribe(data=>{
      this.formData.append('cardDeliv', data);
    });
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "La date d'expiration doit d'être supérieure à la date de délivrance"
        };
      }
      return {};
    }
  }

  onValueChanged(data?: any) {
    if (!this.agentForm) {
      return;
    }
    const form = this.agentForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  submitPiecePhoto(file: FileDto) {
    this.loadingPiecePhoto = true;
    this.agentService.saveAgentFilePhoto(file).subscribe(resp => {
      this.loadingPiecePhoto = false;
      if (resp["status"] === 1) {
        this.messPiecePhoto = "Photo de la pièce enregistré avec succès."
      }
      else {
        this.messPiecePhoto = "Photo de la pièce non enregistré."
      }
    })
  }

  submitRegisteComPhoto(file: FileDto) {
    this.loadingRegistreCom = true;
    this.agentService.saveAgentFileRegistre(file).subscribe(resp => {
      this.loadingRegistreCom = false;
      if (resp["status"] === 1) {
        this.messRegistreComPhoto = "Photo du registre enregistré avec succès."
      }
      else {
        this.messRegistreComPhoto = "Photo du registre non enregistré."
      }
    })
  }



  onSubmit() {
    this.loading = true;
    const identifiant = this.editAgentId;
    const firstname = this.agentForm.get('firstname').value;
    const lastname = this.agentForm.get('lastname').value;
    const telephone = this.agentForm.get('telephone').value;
    const phoneM = this.agentForm.get('phoneM').value;
    const phoneSm = this.agentForm.get('phoneSm').value;
    const birthday = this.agentForm.get('birthday').value;
    const cityOfBirth = this.agentForm.get('cityOfBirth').value;
    const regions = this.agentForm.get('regions').value;
    const cercle = this.agentForm.get('cercle').value;
    const ville = this.agentForm.get('ville').value;
    const commune = this.agentForm.get('commune').value;
    const quartier = this.agentForm.get('quartier').value;
    const numberOfCard = this.agentForm.get('numberOfCard').value;
    const cardDeliverDate = this.agentForm.get('cardDeliverDate').value;
    const cardExpDate = this.agentForm.get('cardExpDate').value;
    const lieuDeliver = this.agentForm.get('lieuDeliver').value;

    this.formData.append('idagent', identifiant);
    this.formData.append('firstName', firstname);
    this.formData.append('lastName', lastname);
    this.formData.append('phone', telephone);
    this.formData.append('phoneM', phoneM);
    this.formData.append('phoneSm', phoneSm);
    this.formData.append('birthday', birthday);
    this.formData.append('cityOfBirth', cityOfBirth);
    this.formData.append('region', regions);
    this.formData.append('cercle', cercle);
    this.formData.append('ville', ville);
    this.formData.append('commune', commune);
    this.formData.append('quartier', quartier);
    this.formData.append('numPiece', numberOfCard);
    this.formData.append('dateDeliv', cardDeliverDate);
    this.formData.append('dateExp', cardExpDate);
    this.formData.append('placeOfDeliv', lieuDeliver);
    console.log(this.formData.get('phone'))
    this.agentService.updateAgent(this.formData).subscribe(resp => {
      console.log(resp)
      this.loading = false;
      this.agent = resp;
      if (resp["status"] === 1) {
        this.agentForm.reset({
          identifiant: '',
          firstname: '',
          lastname: '',
          telephone: '',
          phoneM: '',
          phoneSm: '',
          birthday: null,
          cityOfBirth: '',
          pays: '',
          regions: '',
          cercle: '',
          ville: '',
          commune: '',
          quartier: '',
          numberOfCard: '',
          typeCard: '',
          cardDeliverDate: null,
          cardExpDate: null,
          lieuDeliver: '',
          cardImageGallery: null
        });
        this.path = '';
        this.agentFormDirective.resetForm();
  
        if (this.authService.isAdmin()) {
          this.router.navigate(['/agent-list-admin']);
        } else {
          this.router.navigate(['/agent-list']);
        }
  
      }
      else{
        this.notErrMess = resp["msg"];
      }

    }, err => {
      this.loading = false;
      this.errMess = err;
    });
  }

}
