import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { AddUserComponent } from './components/user/add-user/add-user.component';
import { ListUserComponent } from './components/user/list-user/list-user.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DetailsUserComponent } from './components/user/details-user/details-user.component';
import { HomeComponent } from './components/home/home.component';
import { EditUserComponent } from './components/user/edit-user/edit-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AddAgentComponent } from './components/agent/add-agent/add-agent.component';
import { EditAgentComponent } from './components/agent/edit-agent/edit-agent.component';
import { ListAgentComponent } from './components/agent/list-agent/list-agent.component';
import { DetailsAgentComponent } from './components/agent/details-agent/details-agent.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgbPagination, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { JwtInterceptorInterceptor } from './components/services/interceptor/jwt-interceptor.interceptor';
import { UserProfilComponent } from './components/user/user-profil/user-profil.component';
import { ResetUserPasswordComponent } from './components/user/reset-user-password/reset-user-password.component';
import { ChangeUserPasswordComponent } from './components/user/change-user-password/change-user-password.component';
import { ListAgentAdminComponent } from './components/agent/list-agent-admin/list-agent-admin.component';
import { ListFilterPipe } from './shared/table-filter-user';
import { ListFilterPipeAgent } from './shared/table-filter-agent';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AddUserComponent,
    ListUserComponent,
    PageNotFoundComponent,
    DetailsUserComponent,
    HomeComponent,
    EditUserComponent,
    HeaderComponent,
    FooterComponent,
    AddAgentComponent,
    EditAgentComponent,
    ListAgentComponent,
    DetailsAgentComponent,
    UserProfilComponent,
    ResetUserPasswordComponent,
    ChangeUserPasswordComponent,
    ListAgentAdminComponent,
    ListFilterPipe,
    ListFilterPipeAgent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    NgbPaginationModule

  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
