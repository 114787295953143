import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { baseUrl } from 'src/app/shared/base-url';
import { User } from '../../entities/user';
import { UserDto } from '../../entities/userDto';
import { ProcessHttpMsgService } from '../error/process-http-msg.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,
    private processHttpMsgService: ProcessHttpMsgService,
    private loginService: LoginService) { }

  getAllUsers(): Observable<User[]> {
    return this.http.post<User[]>(baseUrl + 'user/all', {})
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getUserById(id: string): Observable<User> {
    let body = {
      "idUser": id
    };

    return this.http.post<User>(baseUrl + 'user/search/id', body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getUserByEmail(email: string): Observable<User> {

    return this.http.get<User>(baseUrl + 'get/user?email=' + email)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  saveUser(user: UserDto): Observable<User> {
    return this.http.post<User>(baseUrl + 'user/save', user)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateUser(user: UserDto, id): Observable<any> {
    return this.http.post(baseUrl + 'user/update', user)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getAllUsersByPages(pageNumber: number): Observable<User[]> {
    return this.http.get<User[]>(baseUrl + 'get/user/page?number=' + pageNumber + '&size=' + 10)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  fullTextSearch(keyword, pageNumber: number): Observable<User[]> {
    return this.http.get<User[]>(baseUrl + 'search/user/page?keyword=' + keyword + '&number=' + pageNumber + '&size=' + 5)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  deleteUser(id: string): Observable<any> {
    let body = {
      "idUser": id
    };
    return this.http.post(baseUrl + 'user/delete', body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  resetUserPassword(email: string): Observable<boolean> {
    return this.http.get<boolean>(baseUrl + 'user/forgot-password?email=' + email)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  changeUserPassword(iduser: string, oldpassword: string, newpassword: string, confirmpassword: string): Observable<any> {
    let body ={
      "idUser": iduser,
      "password": oldpassword,
      "passNew": newpassword,
      "confirmPass": confirmpassword
    }
    return this.http.post<boolean>(baseUrl + 'user/pass/update', body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }


}
