<nav class="navbar navbar-expand-lg  my-navbar">
    <a class="navbar-brand" href="#">
        <img width="auto" height="50" src="assets/images/logotransp.png" alt="Sama by UBA">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">Menu</span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
            <a class="nav-item nav-link" routerLink="/">Accueil <span
                    class="sr-only">((current))</span></a>

        </div>
        <div class="navbar-nav" *ngIf="isAuthenticated()">
            <a class="nav-item nav-link" *ngIf="isAdmin() || isBackOffice()" routerLink="/user-list" routerLinkActive="active">Utilisateurs</a>
            <a class="nav-item nav-link" *ngIf="isManager() || isBackOffice()" routerLink="/agent-list" routerLinkActive="active">Agents</a>
            <a class="nav-item nav-link" *ngIf="isAdmin()" routerLink="/agent-list-admin" routerLinkActive="active">Agents</a>
        </div>

        <div class="navbar-nav ml-auto" *ngIf="!isAuthenticated()">
            <a class="nav-item nav-link" routerLink='/login' routerLinkActive="active">Se connecter</a>
        </div>
        <div class="navbar-nav dropdown ml-auto" *ngIf="isAuthenticated()">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{ getUserName }}
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" routerLink="/user-profil" [queryParams]="{ id: getUserId }" routerLinkActive="active">Profil</a>
                <a class="dropdown-item" style="cursor: pointer;" (click)="logOut()">Se deconnecter</a>
            </div>
        </div>
    </div>
</nav>