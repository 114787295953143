import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Agent } from '../../entities/agent';
import { AgentService } from '../../services/agent/agent.service';
import * as $ from 'node_modules/jquery/dist/jquery.min.js';
import { LoginService } from '../../services/login/login.service';
import { baseURLFiles } from 'src/app/shared/base-url';



@Component({
  selector: 'app-details-agent',
  templateUrl: './details-agent.component.html',
  styleUrls: ['./details-agent.component.scss']
})
export class DetailsAgentComponent implements OnInit {
  agent: Agent | null;
  errMess: string;
  baseFilesUrl: string = baseURLFiles;
  date = new Date();

  constructor(private route: ActivatedRoute,
    private agentService: AgentService,
    private loginService: LoginService) {


  }



  isAdmin(): boolean {
    return this.loginService.isAdmin();
  }

  isManager(): boolean {
    return this.loginService.isManager();
  }

  isBackOffice(): boolean {
    return this.loginService.isBackOffice();
  }



  ngOnInit(): void {
    let id = this.route.snapshot.paramMap.get('idagent');
    this.getAgentById(id);

    $(document).ready(function () {
      $("#gal").on('click', '[data-toggle="lightbox"]', function (event) {
        event.preventDefault();
        $(this).ekkoLightbox();
      });
    });
  }

  getAgentById(id: string){
    this.agentService.getAgentById(id).subscribe(response => {
      this.agent = response;
    
      switch (response.pays) {
        case "BI":
          this.agent.pays = "Burundi";
          break;
        case "CI":
          this.agent.pays = "Côte d'Ivoire";
          break;
        case "ML":
          this.agent.pays = "Mali";
          break;
        case "SN":
          this.agent.pays = "Sénégal";
          break;

        default:
          break;
      }


    }, err => {
      this.errMess = err;
    });

  }

  disActivate(id:string) {

    this.agentService.disactivateAgent(id).subscribe(state => {
      this.agent = state;
      if (state) {
        this.getAgentById(id);
      }
    }, err => {
      this.errMess = err;
    });
  }

  activate(id:string) {
    this.agentService.activateAgent(id).subscribe(state => {
      this.agent = state;
      if (state) {
        this.getAgentById(id);
      }

    }, err => {
      this.errMess = err;
    });
  }

}
