import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Agent } from '../../entities/agent';
import { AgentService } from '../../services/agent/agent.service';
import { LoginService } from '../../services/login/login.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { ListAgentComponent } from '../list-agent/list-agent.component';
import { concat, Observable, ReplaySubject } from 'rxjs';
import { FileDto } from '../../entities/fileDto';
defineLocale('fr', frLocale);

@Component({
  selector: 'app-add-agent',
  templateUrl: './add-agent.component.html',
  styleUrls: ['./add-agent.component.scss']
})
export class AddAgentComponent implements OnInit {

  agent: Agent;
  pathIden: string;
  pathRegistre: string;
  bufferPathIden: string;
  bufferPathRegistre: string;
  formData = new FormData();
  loading: boolean;
  loadingPiecePhoto: boolean;
  loadingRegistreCom: boolean;
  pays: string;
  typeCard: string;
  agentPhotoFile = new FileDto();
  agentRegistreComFile = new FileDto();
  messPiecePhoto: string;
  messRegistreComPhoto: string;
  messPiecePhotoNotValid: string;
  messRegistreComPhotoNotValid: string;
  // Form code
  agentForm: FormGroup;
  notLogged = false;
  errMess: string;
  notErrMess: string;
  @ViewChild('aform', { static: false }) agentFormDirective;
  @ViewChild(ListAgentComponent) listAgent;

  formErrors = {
    'identifiant': '',
    'firstname': '',
    'lastname': '',
    'telephone': '',
    'phoneM': '',
    'phoneSm': '',
    'birthday': '',
    'cityOfBirth': '',
    'pays': '',
    'regions': '',
    'cercle': '',
    'ville': '',
    'commune': '',
    'quartier': '',
    'numberOfCard': '',
    'typeCard': '',
    'cardDeliverDate': '',
    'cardExpDate': '',
    'lieuDeliver': '',
    'cardImagePhotoIden': '',
  };

  validationMessages = {
    'identifiant': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 4 lettres.'
    },
    'firstname': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'lastname': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'telephone': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 8 lettres.'
    },
    'phoneM': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 8 lettres.'
    },
    'phoneSm': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 8 lettres.'
    },
    'birthday': {
      'required': 'Champ obligatoire.'
    },
    'cityOfBirth': {
      'required': 'Champ obligatoire.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'pays': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'regions': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'cercle': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'ville': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'commune': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 1 lettre.'
    },
    'quartier': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 3 lettres.'
    },
    'numberOfCard': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.'
    },
    'typeCard': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'cardDeliverDate': {
      'required': 'Champ obligatoire.'
    },
    'cardExpDate': {
      'required': 'Champ obligatoire.'
    },
    'lieuDeliver': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'cardImagePhotoIden': {
      'required': 'Champ obligatoire.'
    }
  };


  constructor(private fb: FormBuilder, private authService: LoginService, private agentService: AgentService,
    private router: Router) {
    this.createForm();
  }



  ngOnInit(): void {

  }


  onFilePhotoIdenChange(event): void {
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.agentPhotoFile.image = base64;
      if (this.identifiant.invalid) {
        this.messPiecePhotoNotValid = "Renseigner correctement le champ identifiant"
      }
      else {
        this.submitPiecePhoto(this.agentPhotoFile);
      }
    });

  }

  onFilePhotoRegistreComChange(event): void {
    this.convertFile(event.target.files[0]).subscribe(base64 => {
      this.agentRegistreComFile.image = base64;
      if (this.identifiant.invalid) {
        this.messRegistreComPhotoNotValid = "Renseigner correctement le champ identifiant"
      }
      else {
        this.submitRegisteComPhoto(this.agentRegistreComFile);
      }
    });
  }

  get identifiant() { return this.agentForm.get('identifiant'); }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  createForm() {
    this.agentForm = this.fb.group({

      identifiant: ['', [
        Validators.required,
        Validators.minLength(4),
        Validators.pattern('^[a-z0-9àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      firstname: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')

      ]
      ],
      lastname: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      telephone: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^[0-9]*$')
      ]
      ],

      birthday: [null, [
        Validators.required
      ]
      ]
      ,
      cityOfBirth: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ]
      ,
      pays: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      regions: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      cercle: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      ville: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      commune: ['', [
        Validators.required,
        Validators.minLength(1),
        Validators.pattern('^[a-zA-Z0-9 àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      quartier: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z0-9 àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      numberOfCard: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9/]*$')
      ]
      ]
      ,
      typeCard: ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      cardDeliverDate: [null, [
        Validators.required
      ]
      ]
      ,
      cardExpDate: [null, [
        Validators.required
      ]
      ]
      ,
      lieuDeliver: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      cardImagePhotoIden: [null, [
        Validators.required
      ]
      ],
      cardImageRegistreCom: [null],
      phoneM: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^[0-9]*$')
      ]
      ],
      phoneSm: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^[0-9]*$')
      ]
      ]
    }, { validator: this.dateLessThan('cardDeliverDate', 'cardExpDate') });
    this.agentForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages

    this.agentForm.get('pays').valueChanges.subscribe(data => {
      this.pays = data;
    });

    this.agentForm.get('typeCard').valueChanges.subscribe(data => {
      this.typeCard = data;
    });

    this.agentForm.get('identifiant').valueChanges.subscribe((data: string) => {
      this.agentPhotoFile.idagent = data;
      this.agentRegistreComFile.idagent = data;
    });
  }

  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let f = group.controls[from];
      let t = group.controls[to];
      if (f.value > t.value) {
        return {
          dates: "La date d'expiration doit d'être supérieure à la date de délivrance"
        };
      }
      return {};
    }
  }

  onValueChanged(data?: any) {
    if (!this.agentForm) {
      return;
    }
    const form = this.agentForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  submitPiecePhoto(file: FileDto) {
    this.loadingPiecePhoto = true;
    this.agentService.saveAgentFilePhoto(file).subscribe(resp => {
      this.loadingPiecePhoto = false;
      if (resp["status"] === 1) {
        this.messPiecePhoto = "Photo de la pièce enregistré avec succès."
      }
      else {
        this.messPiecePhoto = "Photo de la pièce non enregistré."
      }
    },err=>{
      this.loadingPiecePhoto = false;
      this.messPiecePhotoNotValid = err.message;
    })
  }

  submitRegisteComPhoto(file: FileDto) {
    this.loadingRegistreCom = true;
    this.agentService.saveAgentFileRegistre(file).subscribe(resp => {
      this.loadingRegistreCom = false;
      if (resp["status"] === 1) {
        this.messRegistreComPhoto = "Photo du registre enregistré avec succès."
      }
      else {
        this.messRegistreComPhoto = "Photo du registre non enregistré."
      }
    },err=>{
      this.loadingRegistreCom = false;
      this.messRegistreComPhotoNotValid = err.message;
    })
  }


  onSubmit() {
    this.loading = true;

    const identifiant = this.agentForm.get('identifiant').value;
    const firstname = this.agentForm.get('firstname').value;
    const lastname = this.agentForm.get('lastname').value;
    const telephone = this.agentForm.get('telephone').value;
    const phoneM = this.agentForm.get('phoneM').value;
    const phoneSm = this.agentForm.get('phoneSm').value;
    const birthday = this.agentForm.get('birthday').value;
    const cityOfBirth = this.agentForm.get('cityOfBirth').value;
    const regions = this.agentForm.get('regions').value;
    const cercle = this.agentForm.get('cercle').value;
    const ville = this.agentForm.get('ville').value;
    const commune = this.agentForm.get('commune').value;
    const quartier = this.agentForm.get('quartier').value;
    const numberOfCard = this.agentForm.get('numberOfCard').value;
    const cardDeliverDate = this.agentForm.get('cardDeliverDate').value;
    const cardExpDate = this.agentForm.get('cardExpDate').value;
    const lieuDeliver = this.agentForm.get('lieuDeliver').value;
    const user = this.authService.username;

    this.formData.append('idagent', identifiant);
    this.formData.append('firstName', firstname);
    this.formData.append('lastName', lastname);
    this.formData.append('phone', telephone);
    this.formData.append('phoneM', phoneM);
    this.formData.append('phoneSm', phoneSm);
    this.formData.append('birthday', birthday);
    this.formData.append('cityOfBirth', cityOfBirth);
    this.formData.append('pays', this.pays);
    this.formData.append('region', regions);
    this.formData.append('cercle', cercle);
    this.formData.append('ville', ville);
    this.formData.append('commune', commune);
    this.formData.append('quartier', quartier);
    this.formData.append('numPiece', numberOfCard);
    this.formData.append('cardDeliv', this.typeCard);
    this.formData.append('dateDeliv', cardDeliverDate);
    this.formData.append('dateExp', cardExpDate);
    this.formData.append('placeOfDeliv', lieuDeliver);
    this.formData.append('user', user);
    
    this.agentService.saveAgent(this.formData).subscribe(resp => {

      this.loading = false;
      console.log(resp)
      if (resp["status"] === 1) {
        this.agentForm.reset({
          identifiant: '',
          firstname: '',
          lastname: '',
          telephone: '',
          phoneM: '',
          phoneSm: '',
          birthday: null,
          cityOfBirth: '',
          pays: '',
          regions: '',
          cercle: '',
          ville: '',
          commune: '',
          quartier: '',
          numberOfCard: '',
          typeCard: '',
          cardDeliverDate: null,
          cardExpDate: null,
          lieuDeliver: '',
          cardImageGallery: null
        });
        this.pathIden = '';
        this.pathRegistre = '';
        this.agentFormDirective.resetForm();

        if (this.authService.isAdmin()) {
          this.router.navigate(['/agent-list-admin']);
        } else {
          this.router.navigate(['/agent-list']);
        }

      }
      else {
        this.notErrMess = resp["msg"];
      }

    }, err => {
      this.loading = false;
      this.errMess = err;
    });


  }



}
