import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddAgentComponent } from './components/agent/add-agent/add-agent.component';
import { DetailsAgentComponent } from './components/agent/details-agent/details-agent.component';
import { EditAgentComponent } from './components/agent/edit-agent/edit-agent.component';
import { ListAgentAdminComponent } from './components/agent/list-agent-admin/list-agent-admin.component';
import { ListAgentComponent } from './components/agent/list-agent/list-agent.component';
import { AuthenticationGuard } from './components/guards/authentication.guard';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AddUserComponent } from './components/user/add-user/add-user.component';
import { ChangeUserPasswordComponent } from './components/user/change-user-password/change-user-password.component';
import { DetailsUserComponent } from './components/user/details-user/details-user.component';
import { EditUserComponent } from './components/user/edit-user/edit-user.component';
import { ListUserComponent } from './components/user/list-user/list-user.component';
import { ResetUserPasswordComponent } from './components/user/reset-user-password/reset-user-password.component';
import { UserProfilComponent } from './components/user/user-profil/user-profil.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'user-list', component: ListUserComponent, canActivate: [AuthenticationGuard] },
  { path: 'add-user', component: AddUserComponent, canActivate: [AuthenticationGuard] },
  { path: 'reset-user-password', component: ResetUserPasswordComponent, canActivate: [AuthenticationGuard] },
  { path: 'change-user-password', component: ChangeUserPasswordComponent, canActivate: [AuthenticationGuard] },
  { path: 'user-profil', component: UserProfilComponent, canActivate: [AuthenticationGuard] },
  { path: 'user/:idUser', component: DetailsUserComponent, canActivate: [AuthenticationGuard] },
  { path: 'edit-user/:idUser', component: EditUserComponent, canActivate: [AuthenticationGuard] },
  { path: 'agent-list', component: ListAgentComponent, canActivate: [AuthenticationGuard] },
  { path: 'agent-list-admin', component: ListAgentAdminComponent, canActivate: [AuthenticationGuard] },
  { path: 'add-agent', component: AddAgentComponent, canActivate: [AuthenticationGuard] },
  { path: 'agent/:idagent', component: DetailsAgentComponent, canActivate: [AuthenticationGuard] },
  { path: 'edit-agent/:idagent', component: EditAgentComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
