<div class="container" style="margin-top: 25px;">
    <div class="alert alert-danger" role="alert" *ngIf="errMess">
        {{ errMess.message }}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="notErrMess">
        {{ notErrMess }}
    </div>
    <div class="card">
        <div class="card-header">
            <h1>Ajouter un utilisateur</h1>
        </div>

        <div class="card-body">
            <form novalidate [formGroup]="userForm" #lform="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-column">

                    <div class="col-sm my-1">
                        <label for="idUser">Identifiant</label>
                        <div class="input-group">
                            <input id="idUser" type="text" class="form-control" formControlName="idUser" autocomplete="off" pattern required />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.idUser">
                            {{ formErrors.idUser }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <label for="firstName">Prénom</label>
                        <div class="input-group">
                            <input id="firstName" type="text" class="form-control" formControlName="firstName" autocomplete="off" pattern required />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.firstName">
                            {{ formErrors.firstName }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <label for="lastName">Nom</label>
                        <div class="input-group">
                            <input id="lastName" type="text" class="form-control" formControlName="lastName" autocomplete="off" pattern required />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.lastName">
                            {{ formErrors.lastName }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <label for="email">Email</label>
                        <div class="input-group">
                            <input id="email" type="email" class="form-control" formControlName="email" autocomplete="off" pattern required />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.email">
                            {{ formErrors.email }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <label for="phone">Téléphone</label>
                        <div class="input-group">
                            <input id="phone" type="tel" class="form-control" formControlName="phone" autocomplete="off" pattern required />

                        </div>
                        <small class="errorsForm" *ngIf="formErrors.phone">
                            {{ formErrors.phone }}
                        </small>
                    </div>

                    <div class="col-sm my-1">
                        <label for="password">Mot de passe</label>
                        <div class="input-group">
                            <input id="password" type="password" class="form-control" formControlName="password" autocomplete="off" required />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.password">
                            {{ formErrors.password }}
                        </small>
                    </div>


                    <div class="col-sm my-1">
                        <div class="form-group">
                            <label for="role">Rôle</label>
                            <select class="form-control" id="role" formControlName="role" required>
                                <option selected></option>
                                <option value="ADMIN">ADMIN</option>
                                <option value="BACKOFFICE">BACK OFFICE</option>
                                <option value="MANAGER">MANAGER</option>
                            </select>
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.role">
                            {{ formErrors.role }}
                        </small>
                    </div>

                    <div class="col-auto my-1">
                        <label for="inlineFormInputGroupBtn" style="color: white;">.</label>
                        <div class="input-group">
                            <div class="my-spinner" *ngIf="loading">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>

                            </div>
                            <button type="submit" class="btn btn-ajout" [disabled]="userForm.invalid" id="inlineFormInputGroupBtn">
                                Enregistrer
                            </button>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    </div>
</div>