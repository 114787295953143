import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '../../entities/user';
import { UserDto } from '../../entities/userDto';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-user-profil',
  templateUrl: './user-profil.component.html',
  styleUrls: ['./user-profil.component.scss']
})
export class UserProfilComponent implements OnInit {

  user: UserDto;
  errMess: string;
  constructor(private userService: UserService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    let id = this.route.snapshot.queryParamMap.get('id');
    this.getUserById(id);
  }

  getUserById(id: string) {
    this.userService.getUserById(id).subscribe(user => {
      this.user = this.userToUserDto(user);
    }, err => {
      this.errMess = err;
    });
  }

  userToUserDto(user: User): UserDto {
    const dto: UserDto = new UserDto();

    dto.idUser = user.idUser;
    dto.lastName = user.lastName;
    dto.firstName = user.firstName;
    dto.email = user.email;
    dto.phone = user.phone;

    switch (user.idRole) {
      case "1":
        dto.role = "ADMIN"
        break;
      case "2":
        dto.role = "MANAGER"
        break;
      case "3":
        dto.role = "BACKOFFICE"
        break;

      default:
        break;
    }

    return dto;
  }


}
