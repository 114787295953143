import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {  baseUrl } from 'src/app/shared/base-url';
import { Agent } from '../../entities/agent';
import { FileDto } from '../../entities/fileDto';
import { ProcessHttpMsgService } from '../error/process-http-msg.service';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  constructor(private http: HttpClient,
    private processHttpMsgService: ProcessHttpMsgService,
    private loginService: LoginService) { }

  getAllAgents(): Observable<Agent[]> {
    return this.http.get<Agent[]>(baseUrl + 'get/all/agents')
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getAgentById(id: string): Observable<Agent> {
    let body = {
      "idagent": id
    };
    return this.http.post<Agent>(baseUrl + 'agent/search/id',body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }


  saveAgent(formData: FormData): Observable<any> {

    return this.http.post(baseUrl + 'agent/save', formData)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  saveAgentFilePhoto(agentFile: FileDto): Observable<any> {
    let body ={
      idagent: agentFile.idagent,
      type: "photo",
      image: agentFile.image,
      user: this.loginService.username
    };
    console.log(body);
    return this.http.post(baseUrl + 'agent/photo/save', body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  saveAgentFileRegistre(agentFile: FileDto): Observable<any> {
    let body ={
      idagent: agentFile.idagent,
      type: "registreCom",
      image: agentFile.image,
      user: this.loginService.username
    };
    console.log(body);
    return this.http.post(baseUrl + 'agent/photo/save', body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  updateAgent(formData: FormData): Observable<any> {
    return this.http.post(baseUrl + 'agent/update', formData)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getAllAgentsByPages(pageNumber: number): Observable<Agent[]> {
    return this.http.get<Agent[]>(baseUrl + 'get/agent/page?number=' + pageNumber + '&size=' + 10)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getAllAgentPhp(): Observable<Agent[]> {
    return this.http.post<Agent[]>(baseUrl + '/agent/all', {})
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  getAgentsByCreatorUser(id: string): Observable<Agent[]> {
    let body = {
      "id": id
    };
    return this.http.post<Agent[]>(baseUrl + 'user/view/agent', body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  fullTextSearch(keyword, pageNumber: number): Observable<Agent[]> {
    return this.http.get<Agent[]>(baseUrl + 'search/agent/page?keyword=' + keyword + '&number=' + pageNumber + '&size=' + 5)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }


  deleteAgent(id: string): Observable<any> {
    let body = {
      "idagent": id
    };
    return this.http.post(baseUrl + 'agent/delete', body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }


  activateAgent(id:string): Observable<any> {
    let body = {
      "idagent": id,
      "isActivated": "1"
    };
    return this.http.post(baseUrl + 'agent/status', body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

  disactivateAgent(id:string): Observable<any> {
    let body = {
      "idagent": id,
      "isActivated": "0"
    };
    return this.http.post(baseUrl + 'agent/status', body)
      .pipe(catchError(this.processHttpMsgService.handleError));
  }

}
