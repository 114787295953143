import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../../entities/user';
import { UserDto } from '../../entities/userDto';
import { LoginService } from '../../services/login/login.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  userDto: UserDto;
  rolename: string;
  // Form code
  userForm: FormGroup;
  notLogged = false;
  errMess: string;
  notErrMess: string;

  loading: boolean;
  @ViewChild('lform', { static: false }) userFormDirective;


  formErrors = {
    'idUser': '',
    'firstName': '',
    'lastName': '',
    'email': '',
    'phone': '',
    'password': '',
    'role': '',
  };

  validationMessages = {
    'idUser': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'firstName': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'lastName': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.',
      'minlength': 'Pas moins de 2 lettres.'
    },
    'email': {
      'required': 'Champ obligatoire.',
      'pattern': 'Email non valide.'
    },
    'phone': {
      'required': 'Champ obligatoire.',
      'pattern': 'Téléphone non valide.',
      'minlength': 'Pas moins de 8 chiffres.'
    },
    'password': {
      'required': 'Champ obligatoire.',
      'minlength': 'Pas moins de 4 caractères.',
    },
    'role': {
      'required': 'Champ obligatoire.'
    }
  };


  constructor(private fb: FormBuilder, private authService: LoginService, private userService: UserService,
    private router: Router) {
    this.createForm();
  }



  ngOnInit(): void {

  }

  createForm() {
    this.userForm = this.fb.group({
      idUser: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-z0-9àèòùáéâêîôûïçæœ]*$')
      ]
      ]
      ,
      firstName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      lastName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      email: ['', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ]
      ],
      phone: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^[0-9]*$')
      ]
      ],
      password: ['', [
        Validators.required,
        Validators.minLength(4),
      ]
      ]
      ,
      role: ['', [
        Validators.required
      ]
      ]
    });

    this.userForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages

    // this.userForm.get('role').valueChanges.subscribe(data=>{
    //   this.rolename = data;
    // });
  }

  onValueChanged(data?: any) {
    if (!this.userForm) {
      return;
    }
    const form = this.userForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  // selectOption(event: any) {
  //   this.rolename = event.target.value;

  // }

  onSubmit() {
    this.loading = true;
    this.userDto = this.userForm.value;

    console.log(this.userForm.value);

    this.userService.saveUser(this.userDto).subscribe(resp => {
      this.loading = false;
      console.log("Resultat Statut::", resp["status"])
      console.log("Resultat Message::", resp["msg"])

      if (resp["status"] == 1) {
        this.router.navigate(['/user-list']);
        this.userForm.reset({
          idUser: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          password: '',
          role: ''
        });
        this.userFormDirective.resetForm();
      }
      else{
        this.notErrMess = resp["msg"];
      }

    }, err => {
      this.loading = false;
      this.errMess = err;

    });


  }


}
