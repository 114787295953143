import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { Agent } from '../../entities/agent';
import { AgentService } from '../../services/agent/agent.service';
import { LoginService } from '../../services/login/login.service';

@Component({
  selector: 'app-list-agent-admin',
  templateUrl: './list-agent-admin.component.html',
  styleUrls: ['./list-agent-admin.component.scss']
})
export class ListAgentAdminComponent implements OnInit {

  agents: Agent[] | null;
  allAgents: Agent[] | null;
  isDeleted: boolean = false;
  isEdited: boolean = false;
  errMess: string;
  noAgentMess: string;
  @ViewChild('lgModal', { static: false }) lgModal?: ModalDirective;
  modalRef?: BsModalRef;
  @ViewChild('lgeditModal', { static: false }) lgeditModal?: ModalDirective;
  @ViewChild('sform', { static: false }) searchFormDirective;
  editmodalRef?: BsModalRef;
  agentId: string;
  state: boolean;
  email: string;

  page = 1;
  pages = 10;
  pageCollectionSize: number;
  searchTerm: string;

  searchForm: FormGroup;

  formErrors = {
    'keyword': ''
  };

  validationMessages = {
    'keyword': {
      'required': 'Champ obligatoire.',
      'pattern': 'Format non valide.'
    }
  };




  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };



  constructor(
    private fb: FormBuilder,
    private agentService: AgentService,
    private loginService: LoginService,
    private modalService: BsModalService
  ) {
    this.createForm();
  }


  createForm() {
    this.searchForm = this.fb.group({

      keyword: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9 àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ]
    });
    this.searchForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages
    this.searchForm.get('keyword').valueChanges.subscribe(data => {
      this.searchTerm = data;
    });
  }

  onValueChanged(data?: any) {
    if (!this.searchForm) {
      return;
    }
    const form = this.searchForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }


  ngOnInit(): void {
    // this.email = this.loginService.username;
    this.getAllAgents()
  }

  getAllAgents() {
    this.agentService.getAllAgentPhp().subscribe(response => {
      if (response["status"] === 1) {
        this.pageCollectionSize = response['resultat'].length;
        this.agents = response['resultat'];
        this.allAgents = this.agents;
        console.log(this.allAgents);
      }else{
        this.noAgentMess = response['msg'];
      }


    }, err => {
      console.error(err);
      this.errMess = err;

    });
  }

  search(value: string): void {
    this.agents = this.allAgents.filter((val) => val.idagent.toLowerCase().includes(value));
    this.pageCollectionSize = this.agents.length;
  }

  deleteAgent(id) {
    this.agentService.deleteAgent(id).subscribe(resp => {
      this.isDeleted = true;
      if (this.isDeleted) {
        // this.isDeleted = true;
        this.getAllAgents();
      }
    }, err => {
      this.errMess = err;
    });
  }

 

  isAdmin(): boolean {
    return this.loginService.isAdmin();
  }

  isManager(): boolean {
    return this.loginService.isManager();
  }

  isBackOffice(): boolean {
    return this.loginService.isBackOffice();
  }

  openEditModal(template: TemplateRef<any>, id) {
    this.editmodalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.agentId = id;
  }

  setId(id: string) {
    this.agentId = id;

  }


  hideEditModal() {
    this.lgeditModal.hide();
    this.getAllAgents();
  }

  openModal(template: TemplateRef<any>, id: string) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.agentId = id;
  }

  confirm(): void {

    this.isDeleted = true;
    this.modalRef?.hide();

    this.deleteAgent(this.agentId);

  }

  decline(): void {
    this.isDeleted = false;
    this.modalRef?.hide();
  }

  hideModal() {
    this.lgModal.hide();
    this.getAllAgents();
  }


  disActivate(id: string) {

    this.agentService.disactivateAgent(id).subscribe(state => {
      if (state) {
        this.getAllAgents();
      };


    }, err => {
      this.errMess = err;
    });
  }

  activate(id: string) {

    this.agentService.activateAgent(id).subscribe(state => {
      if (state) {
        this.getAllAgents();
      }
    }, err => {
      this.errMess = err;
    });
  }


}
