<!-- research fields -->
<div class="login-container">
    <div class="alert alert-danger" role="alert" *ngIf="errMess">
        {{errMess}}
    </div>
    <div class="icon">
        <img width="170" src="assets/images/logo1.png" alt="Sama by UBA">
    </div>
    <div class="card">
        <div class="card-body">
            <form novalidate [formGroup]="changeForm" #cform="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-row">

                    <div class="col-sm my-1">
                        <label for="oldpassword">Ancien mot de passe</label>
                        <div class="input-group">

                            <input id="oldpassword" type="password" class="form-control" formControlName="oldpassword" autocomplete="new-password" required />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.oldpassword">
                            {{ formErrors.oldpassword }}
                        </small>

                    </div>


                    <div class="col-sm my-1">
                        <label for="newpassword">Nouveau mot de passe</label>
                        <div class="input-group">

                            <input id="newpassword" type="password" class="form-control" formControlName="newpassword" autocomplete="new-password" required />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.newpassword">
                            {{ formErrors.newpassword }}
                        </small>

                    </div>

                    <div class="col-sm my-1">
                        <label for="confirmpassword">Confirmer mot de passe</label>
                        <div class="input-group">

                            <input id="confirmpassword" type="password" class="form-control" formControlName="repassword" autocomplete="new-password" required />
                        </div>
                        <small class="errorsForm" *ngIf="formErrors.repassword">
                            {{ formErrors.repassword }}
                        </small>
                        <small class="errorsForm" *ngIf="!isOk">
                            Les mots de passe doivent être les mêmes
                        </small>
                    </div>


                    <div class="col-auto my-1">
                        <label for="inlineFormInputGroupBtn" style="color: white;"></label>
                        <div class="input-group">

                            <div class="my-spinner" *ngIf="loading">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>

                            </div>
                            <button type="submit" class="btn btn-valid" [disabled]="changeForm.invalid || loading" id="inlineFormInputGroupBtn">

                                Valider
                            </button>
                        </div>
                    </div>



                </div>
            </form>
        </div>
    </div>

</div>