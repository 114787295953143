<div class="container-fluid">
    <div class="page-wrap d-flex flex-row align-items-center">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-12 text-center">
                    <span class="display-1 d-block">404</span>
                    <div class="mb-4 lead">La page que vous cherchée n'a pas été trouvée.</div>
                    <a routerLink="/home" class="btn btn-link">Retour à l'accueil</a>
                </div>
            </div>
        </div>
    </div>
</div>