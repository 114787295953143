<div class="container-fluid">




    <div class="card" *ngIf="agent">
        <div class="card-header">
            <h2>Agent {{ agent.lastName}} {{ agent.firstName}}</h2>
        </div>


        <div class="card-title" style="margin: 10px;">
            <div class="btn-group " role="group" aria-label="Basic example" *ngIf="isBackOffice() || isAdmin()">
                <ng-container *ngIf="agent.isActivated == 1; else template">
                    <button (click)="disActivate(agent.idagent)" class="btn btn-approv">Désapprouver</button>
                </ng-container>
                <ng-template #template>
                    <button (click)="activate(agent.idagent)" class="btn btn-success">Approuver</button>
                </ng-template>

            </div>

            <div class="btn-group ml-auto" style="float: right;" role="group" aria-label="Basic example">

                <button class="btn btn-modif" [routerLink]="[ '/edit-agent', agent.idagent ]"
                    *ngIf="isManager() || isAdmin()">Modifier</button>
                <button class="btn btn-list" [routerLink]="isAdmin() ? ['/agent-list-admin'] : ['/agent-list']">Retour à la liste</button>

            </div>
        </div>
        <div class="card-body">
            <div class="row row-contenu">

                <div class="row row-details">

                    <div>
                        <table class="table table-bordered">

                            <tbody>
                                <tr>
                                    <td class="val">Identifiant</td>
                                    <td>{{ agent.idagent}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Nom</td>
                                    <td>{{ agent.lastName}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Prénom</td>
                                    <td>{{ agent.firstName}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Téléphone</td>
                                    <td>{{ agent.phone}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Date naiss.</td>
                                    <td>{{ agent.birthday}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Lieu de naissance</td>
                                    <td>{{ agent.cityOfBirth}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Pays</td>
                                    <td>{{ agent.pays}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Régions</td>
                                    <td>{{ agent.region}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Cercle</td>
                                    <td>{{ agent.cercle}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Téléphone Master</td>
                                    <td>{{ agent.phoneM}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <table class="table table-bordered">

                            <tbody>
                                <tr>
                                    <td class="val">Ville</td>
                                    <td>{{ agent.ville}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Commune</td>
                                    <td>{{ agent.commune}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Quartier</td>
                                    <td>{{ agent.quartier}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Type de la pièce d'identité</td>
                                    <td>{{ agent.cardDeliv}}</td>
                                </tr>
                                <tr>
                                    <td class="val">N° de la pièce</td>
                                    <td>{{ agent.numPiece}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Date délivr. pièce</td>
                                    <td>{{ agent.dateDeliv}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Date expir. pièce</td>
                                    <td>{{ agent.dateExp}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Lieu délivr. pièce</td>
                                    <td>{{ agent.placeOfDeliv }}</td>
                                </tr>
                                <tr>
                                    <td class="val">Date de création</td>
                                    <td>{{ agent.dateHa}}</td>
                                </tr>
                                <tr>
                                    <td class="val">Téléphone Super Master</td>
                                    <td>{{ agent.phoneSm}}</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>


                </div>



            </div>



        </div>


        <div class="row justify-content-center">

            <div class="row">
                <a [href]="baseFilesUrl + 'photos/' + agent.photo  + '?date=' + date" data-lightbox="roadtrip"><img
                        [src]="baseFilesUrl + 'photos/' + agent.photo  + '?date=' + date" width="300"
                        class="img-fluid img-gal"></a>
                <a [href]="baseFilesUrl + 'registres/' + agent.registreCom + '?date=' + date"
                    data-lightbox="roadtrip"><img [src]="baseFilesUrl + 'photos/' + agent.photo  + '?date=' + date"
                        width="300" class="img-fluid img-gal"></a>
            </div>

        </div>


    </div>


    <div class="my-spinner" *ngIf="!agent">
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h4>Chargement...</h4>
    </div>








</div>