<div class="container">

    <div class="alert alert-danger" role="alert" *ngIf="errMess">
        {{ errMess}}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="notErrMess">
        {{ notErrMess}}
    </div>
    <div class="card">
        <div class="card-header">
            <h1>Ajouter un agent</h1>
        </div>
        <div class="card-body">
            <form novalidate [formGroup]="agentForm" #aform="ngForm" (ngSubmit)="onSubmit()">
                <div class="form-column ">
                    <div class="row">
                        <div class="col-sm my-1">
                            <label for="identifiant">Identifiant</label>
                            <div class="input-group">
                                <input id="identifiant" type="text" class="form-control" formControlName="identifiant" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.identifiant">
                                {{ formErrors.identifiant }}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="firstname">Prénom</label>
                            <div class="input-group">
                                <input id="firstname" type="text" class="form-control" formControlName="firstname" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.firstname">
                                {{ formErrors.firstname }}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="lastname">Nom</label>
                            <div class="input-group">
                                <input id="lastname" type="text" class="form-control" formControlName="lastname" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.lastname">
                                {{ formErrors.lastname }}
                            </small>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-sm my-1">
                            <label for="birthday">Date de naissance</label>
                            <div class="input-group">

                                <input type="date" id="birthday" formControlName="birthday" placeholder="Choisissez une date" class="form-control" autocomplete="off" required>
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.birthday">
                                {{ formErrors.birthday }}
                            </small>
                        </div>



                        <div class="col-sm my-1">
                            <label for="cityOfBirth">Ville de naissance</label>
                            <div class="input-group">
                                <input id="cityOfBirth" type="text" class="form-control" formControlName="cityOfBirth" autocomplete="off" pattern required />

                            </div>
                            <small class="errorsForm" *ngIf="formErrors.cityOfBirth">
                                {{ formErrors.cityOfBirth }}
                            </small>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-sm my-1">
                            <label for="pays">Pays</label>
                            <select class="form-control" id="pays" formControlName="pays" required>
                                <option selected></option>
                                <option value="BI">Burundi</option>
                                <option value="CI">Côte d'Ivoire</option>
                                <option value="ML">Mali</option>
                                <option value="SN">Sénégal</option>
                            </select>

                            <small class="errorsForm" *ngIf="formErrors.pays">
                                {{ formErrors.pays }}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="regions">Régions</label>
                            <div class="input-group">
                                <input id="regions" type="text" class="form-control" formControlName="regions" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.regions">
                                {{ formErrors.regions }}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="cercle">Cercle</label>
                            <div class="input-group">
                                <input id="cercle" type="text" class="form-control" formControlName="cercle" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.cercle">
                                {{ formErrors.cercle }}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="ville">Ville</label>
                            <div class="input-group">
                                <input id="ville" type="text" class="form-control" formControlName="ville" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.ville">
                                {{ formErrors.ville }}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="commune">Commune</label>
                            <div class="input-group">
                                <input id="commune" type="text" class="form-control" formControlName="commune" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.commune">
                                {{ formErrors.commune }}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="quartier">Quartier</label>
                            <div class="input-group">
                                <input id="quartier" type="text" class="form-control" formControlName="quartier" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.quartier">
                                {{ formErrors.quartier }}
                            </small>
                        </div>

                    </div>

                    <div class="row">

                        <div class="col-sm-4 my-1">
                            <label for="telephone">Téléphone</label>
                            <div class="input-group">
                                <input id="telephone" type="text" class="form-control" formControlName="telephone" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.telephone">
                                {{ formErrors.telephone }}
                            </small>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm my-1">
                            <label for="typeCard">Type de la Carte</label>
                            <select class="form-control" id="typeCard" formControlName="typeCard" required>
                                <option selected></option>
                                <option value="Carte NINA">Carte NINA</option>
                                <option value="Carte Nationale">Carte d'identité Nationale</option>
                                <option value="Carte Consulaire">Carte Consulaire</option>
                                <option value="Passeport">Passeport</option>
                            </select>


                            <small class="errorsForm" *ngIf="formErrors.typeCard">
                                {{ formErrors.typeCard }}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="numberOfCard">N° de la pièce d'identité</label>
                            <div class="input-group">
                                <input id="numberOfCard" type="text" class="form-control" formControlName="numberOfCard" autocomplete="off" pattern required />

                            </div>
                            <small class="errorsForm" *ngIf="formErrors.numberOfCard">
                                {{ formErrors.numberOfCard }}
                            </small>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-sm my-1">
                            <label for="cardDeliverDate">Date de délivrance</label>
                            <div class="input-group">

                                <input type="date" id="cardDeliverDate" formControlName="cardDeliverDate" placeholder="Choisissez une date" class="form-control" autocomplete="off" required>
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.cardDeliverDate">
                                {{ formErrors.cardDeliverDate }}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="cardExpDate">Date d'expiration</label>
                            <div class="input-group">

                                <input type="date" id="cardExpDate" formControlName="cardExpDate" placeholder="Choisissez une date" class="form-control" autocomplete="off" required>
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.cardExpDate">
                                {{ formErrors.cardExpDate }}
                            </small>
                            <small class="errorsForm" *ngIf="agentForm.errors?.dates">
                                {{ agentForm.errors?.dates}}
                            </small>
                        </div>

                        <div class="col-sm my-1">
                            <label for="lieuDeliver">Lieu de délivrance</label>
                            <div class="input-group">

                                <input type="text" id="lieuDeliver" formControlName="lieuDeliver" class="form-control" autocomplete="off" pattern required>
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.lieuDeliver">
                                {{ formErrors.lieuDeliver }}
                            </small>
                        </div>

                       
                    </div>

                    <div class="row">

                        <div class="col-sm-4 my-1">
                            <label for="phoneM">Téléphone Master</label>
                            <div class="input-group">
                                <input id="phoneM" type="text" class="form-control" formControlName="phoneM" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.phoneM">
                                {{ formErrors.phoneM }}
                            </small>
                        </div>

                        <div class="col-sm-4 my-1">
                            <label for="phoneSm">Téléphone Super Master</label>
                            <div class="input-group">
                                <input id="phoneSm" type="text" class="form-control" formControlName="phoneSm" autocomplete="off" pattern required />
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.phoneSm">
                                {{ formErrors.phoneSm }}
                            </small>
                        </div>
                    </div>


                    <fieldset class="row row-file" [disabled]="identifiant.invalid">

                        <div class="col-sm my-1">
                            <label for="cardImagePhotoIden">Selectionner une photo</label>
                            <div class="input-group">

                                <input type="file" class="form-control-file" formControlName="cardImagePhotoIden" id="cardImagePhotoIden" (change)="onFilePhotoIdenChange($event)" required>
                            </div>
                            <small class="errorsForm" *ngIf="formErrors.cardImagePhotoIden">
                                {{ formErrors.cardImagePhotoIden }}
                            </small>
                        </div>
                        <div class="my-spinner" *ngIf="loadingPiecePhoto">
                            <div class="spinner-border text-secondary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="alert alert-danger" role="alert" *ngIf="messPiecePhotoNotValid && !messPiecePhoto">
                            {{ messPiecePhotoNotValid}}
                        </div>
                        <div class="alert alert-success" role="alert" *ngIf="messPiecePhoto">
                            {{ messPiecePhoto}}
                        </div>

                    </fieldset>

                    <fieldset class="row row-file" [disabled]="identifiant.invalid">

                        <div class="col-sm my-1">
                            <label for="cardImageRegistreCom">Selectionner une photo</label>
                            <div class="input-group">

                                <input type="file" class="form-control-file custom-file" formControlName="cardImageRegistreCom" (change)="onFilePhotoRegistreComChange($event)" id="cardImageRegistreCom">
                            </div>
                        </div>

                        <div class="my-spinner" *ngIf="loadingRegistreCom">
                            <div class="spinner-border text-secondary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="alert alert-danger" role="alert" *ngIf="messRegistreComPhotoNotValid && !messRegistreComPhoto">
                            {{ messRegistreComPhotoNotValid}}
                        </div>
                        <div class="alert alert-success" role="alert" *ngIf="messRegistreComPhoto">
                            {{ messRegistreComPhoto}}
                        </div>

                    </fieldset>


                    <div class="col-auto my-1">
                        <label for="inlineFormInputGroupBtn" style="color: white;">.</label>
                        <div class="input-group">
                            <div class="my-spinner" *ngIf="loading">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-ajout" [disabled]="agentForm.invalid" id="inlineFormInputGroupBtn">
                                Enregistrer
                            </button>
                        </div>
                    </div>

                </div>
            </form>

        </div>
    </div>
</div>