<div class="container-fluid">

    <div class="alert alert-success" role="alert" *ngIf="isDeleted">
        Utlisateur supprimé avec succès.
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="users && errMess">
        {{ errMess.message }}
    </div>

    <div [config]="config" bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
        aria-labelledby="dialog-sizes-name1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 id="dialog-sizes-name1" class="modal-title pull-left">Agent</h4>
                    <button type="button" class="btn-close close pull-right" (click)="hideModal()" aria-label="Close">
                        <span aria-hidden="true" class="visually-hidden">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <app-add-user></app-add-user>
                    <hr>
                    <button type="button" class="btn btn-danger modal-btn" (click)="hideModal()">Fermer</button>
                </div>
            </div>
        </div>
    </div>


    <h1>Liste des utilisateurs</h1>




    <div *ngIf="users && isAdmin()" class="btn-group ml-auto" style="float: right;" role="group"
        aria-label="Basic example">

        <button class="btn btn-ajout" routerLink="/add-user">Ajouter utilisateur</button>

    </div>

    <div class="my-spinner" *ngIf="!users">
        <div class="spinner-border text-secondary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <h4>Chargement...</h4>
    </div>



    <ng-container *ngIf="users">
        <div class="table-responsive-sm">
            <form class="form-inline my-2 my-lg-0" novalidate [formGroup]="searchForm" #sform="ngForm"
                (ngSubmit)="onSearchSubmit()">
                <div class="row">
                    <div class="col-sm my-1">
                        <input class="form-control mr-sm-2" type="search" placeholder="Recherche" aria-label="Search"
                            formControlName="keyword" autocomplete="off" pattern required
                            (input)="search($event.target.value)">
                    </div>
                    <!-- <button class="btn btn-outline-success my-2 my-sm-0" type="submit"
            [disabled]="searchForm.invalid">Chercher</button> -->
                </div>
            </form>

            <!-- <ng-container *ngIf="isSearch; else allTemplate"> -->
            <table class="table table-bordered table-hover">
                <thead class="my-thead">
                    <tr>
                        <th class="col-sm-1 text-center align-middle" scope="col">#</th>
                        <th class="text-center align-middle" scope="col">Identifiant</th>
                        <th class="text-center align-middle" scope="col">Prénom</th>
                        <th class="text-center align-middle" scope="col">Nom</th>
                        <th class="text-center align-middle" scope="col">Email</th>
                        <th class="text-center align-middle" scope="col">Téléphone</th>
                        <th class="text-center align-middle" scope="col">Actions</th>
                    </tr>
                </thead>
                <tbody
                    *ngFor="let user of users | listFilter: searchTerm | slice: (page-1) * pages : page * pages; let i=index">
                    <tr>
                        <th class="text-center align-middle" scope="row">{{ (page -1) * pages + i + 1 }}</th>
                        <td class="text-center align-middle">{{user.idUser}}</td>
                        <td class="text-center align-middle">{{user.firstName}}</td>
                        <td class="text-center align-middle">{{user.lastName}}</td>
                        <td class="text-center align-middle">{{user.email}}</td>
                        <td class="text-center align-middle">{{user.phone}}</td>
                        <td class="text-center align-middle">


                            <div class="btn-group ml-auto" role="group" aria-label="Basic example">
                                <a class="btn btn-voir" [routerLink]="[ '/user', user.idUser ]">Voir</a>
                                <a *ngIf="isAdmin()" class="btn btn-modif"
                                    [routerLink]="[ '/edit-user', user.idUser ]">Modifier</a>
                                <a *ngIf="isAdmin()" class="btn btn-supp"
                                    (click)="openModal(userTemplate, user.idUser)">Supprimer</a>
                            </div>

                        </td>
                    </tr>
                </tbody>
                <div class="row">
                    <div class="col-xs-12 col-12">

                        <ngb-pagination class="pagination-color" [collectionSize]="pageCollectionSize"
                            [pageSize]="pages" [(page)]="page" [maxSize]="5" [boundaryLinks]="true"></ngb-pagination>

                    </div>
                </div>
            </table>

        </div>
    </ng-container>

    <ng-template #userTemplate>

        <div class="modal-body text-center">
            <p>Voulez-vous vraiment supprimer ce compte utilisateur ?</p>
            <div class="btn">
                <button type="button" class="btn btn-supp" (click)="confirm()">Oui</button>
                <button type="button" class="btn btn-non" (click)="decline()">Non</button>

            </div>

        </div>

    </ng-template>




</div>