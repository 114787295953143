import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptorInterceptor implements HttpInterceptor {

  constructor(private authService: LoginService, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const jwt = localStorage.getItem('currtokenag');
    if (jwt != null) {
      const authRequest = request.clone({
        setHeaders: {
          "Authorization": `Bearer ${jwt}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
        }
      
      });
      
      if (this.authService.isTokenExprired(jwt)) {
        this.authService.logOut();
        
        this.router.navigate(['/login']);
      }

      return next.handle(authRequest);


    } else {
      return next.handle(request);
    }





  }
}
