import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../entities/user';
import { UserDto } from '../../entities/userDto';
import { LoginService } from '../../services/login/login.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  user: User;
  editedUser: UserDto;
  rolename: string;
  formData = new FormData();
  // Form code
  userForm: FormGroup;
  notLogged = false;
  errMess: string;
  notErrMess: string;
  @ViewChild('lform', { static: false }) userFormDirective;
  editId: string;
  loading: boolean;

  formErrors = {
    'firstName': '',
    'lastName': '',
    'email': '',
    'phone': '',
    'role': '',
  };

  validationMessages = {
    'firstName': {
      'required': 'Champ obligatoire.',
      'minlength': 'Pas moins de 2 lettres.',
      'pattern': 'Format non valide.'
    },
    'lastName': {
      'required': 'Champ obligatoire.',
      'minlength': 'Pas moins de 2 lettres.',
      'pattern': 'Format non valide.'
    },
    'email': {
      'required': 'Champ obligatoire.',
      'pattern': 'Email non valide.'
    },
    'phone': {
      'required': 'Champ obligatoire.',
      'minlength': 'Pas moins de 8 caractères.',
      'pattern': 'Format non valide.'
    },
    'role': {
      'required': 'Champ obligatoire.'
    }
  };


  constructor(private fb: FormBuilder, private authService: LoginService, private userService: UserService,
    private router: Router, private route: ActivatedRoute) {
    this.createForm();
  }



  ngOnInit(): void {

    this.editId = this.route.snapshot.paramMap.get('idUser');
    this.userService.getUserById(this.editId).subscribe(user => {
      this.user = user;
      this.setFormValue(user);
    }, err => {
      this.errMess = err;
    });

  }


  

  setFormValue(user: User) {

    this.userForm.get('firstName').setValue(user.firstName);
    this.userForm.get('lastName').setValue(user.lastName);
    this.userForm.get('email').setValue(user.email);
    this.userForm.get('phone').setValue(user.phone);

    switch (user.idRole) {
      case "1":
        this.userForm.get('role').setValue("ADMIN");
        break;
      case "2":
        this.userForm.get('role').setValue("MANAGER");
        break;
      case "3":
        this.userForm.get('role').setValue("BACKOFFICE");
        break;
      default:
        break;
    }

  }

  createForm() {
    this.userForm = this.fb.group({

      email: ['', [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
      ]
      ],
      phone: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern('^[0-9]*$')
      ]
      ]
      ,
      firstName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ]
      ,
      lastName: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ]*$')
      ]
      ],
      role: ['', [
        Validators.required
      ]
      ]
    });
    
    this.userForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages

    this.userForm.get('role').valueChanges.subscribe(data=>{
      this.rolename = data;
    });
  }

  onValueChanged(data?: any) {
    if (!this.userForm) {
      return;
    }
    const form = this.userForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    this.loading = true;
    this.editedUser = this.userForm.value;

    this.editedUser.idUser = this.editId;

    this.userService.updateUser(this.editedUser, this.editId).subscribe(resp => {
      this.loading = false;
      
      this.user = resp;

      if (resp["status"] === 1) {
        this.router.navigate(['/user-list'])
        this.userForm.reset({
          firstname: '',
          lastname: '',
          email: '',
          telephone: '',
          roles: ''
        });
        this.userFormDirective.resetForm();
      }
      else{
        this.notErrMess = resp["msg"];
      }
    }, err => {
      this.loading = false;
      this.errMess = err;
    });


  }


}
