import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../services/login/login.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-change-user-password',
  templateUrl: './change-user-password.component.html',
  styleUrls: ['./change-user-password.component.scss']
})
export class ChangeUserPasswordComponent implements OnInit {

  // Form code
  changeForm: FormGroup;
  notLogged = false;
  loading = false;
  errMess: string;
  @ViewChild('cform', { static: false }) changeFormDirective;
  response: boolean;
  isOk: boolean = true;

  formErrors = {
    'oldpassword': '',
    'newpassword': '',
    'repassword': '',
  };

  validationMessages = {
    'oldpassword': {
      'required': 'Champ obligatoire.'
    },
    'newpassword': {
      'required': 'Champ obligatoire.'
    },
    'repassword': {
      'required': 'Champ obligatoire.'
    }
  };


  constructor(private fb: FormBuilder,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService) {
    this.createForm();
  }



  ngOnInit(): void {

  }

  createForm() {
    this.changeForm = this.fb.group({
      oldpassword: ['', [
        Validators.required
      ]
      ],
      newpassword: ['', [
        Validators.required
      ]
      ],
      repassword: ['', [
        Validators.required
      ]
      ]
    });
    this.changeForm.valueChanges
      .subscribe(data => this.onValueChanged(data));

    this.onValueChanged(); //(re)set form validation messages

    this.changeForm.get("repassword").valueChanges.subscribe(data => {
      if (this.changeForm.get("newpassword").value === data) {
        this.isOk = true;
      } else {
        this.isOk = false;
        this.loading = false;
      }
    });

    this.changeForm.get("newpassword").valueChanges.subscribe(data => {
      let redata: string = this.changeForm.get("repassword").value;
      if (redata.length !== 0) {
        if (redata === data) {
          this.isOk = true;
        } else {
          this.isOk = false;
          this.loading = false;
        }
      }

    });
  }

  get f() { return this.changeForm.controls; }


  onValueChanged(data?: any) {
    if (!this.changeForm) {
      return;
    }
    const form = this.changeForm;
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';

        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key] + ' ';
            }
          }
        }
      }
    }
  }

  onSubmit() {
    this.loading = true;
    let iduser = this.loginService.username;
    let newpassword = this.changeForm.get("newpassword").value;
    let repassword = this.changeForm.get("repassword").value;
    let oldpassword = this.changeForm.get("oldpassword").value;

    this.userService.changeUserPassword(iduser, oldpassword, newpassword, repassword).subscribe(resp => {
      this.loading = false;
      console.log(resp)
      if (resp["status"] === 1) {
        this.isOk = true;
      this.changeForm.reset({
        password: '',
        repassword: '',
      });
      // this.isOk = false;
      this.changeFormDirective.resetForm();
      alert("Mot de passe modifier avec succès.Vous allez être redirigé vers la page votre profile.")
      this.router.navigate(["/user-profil"], { queryParams: { id: this.loginService.username } })

      }
      // this.response = resp;
      
    },
      err => {
        this.loading = false;
        this.notLogged = true;
        this.errMess = err;
        console.log(this.errMess);

      });
  }


}
