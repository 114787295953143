import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../services/login/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  username: string = "user name";
  id: string;


  constructor(private authService: LoginService,
    private router: Router) { }

  ngOnInit(): void {
    this.authService.loadToken();
  }

  get getUserId(): string{
    if (this.isAuthenticated()) {
      this.id = this.authService.username;
    }
    return this.id;
  }

  get getUserName(): string {
    if (this.isAuthenticated()) {
      this.authService.loadToken();
      this.username = this.authService.firstname + " " + this.authService.lastname;
    }

    return this.username;
  }

  isAdmin() {
    return this.authService.isAdmin();
  }

  isUser() {
    return this.authService.isUser();
  }

  isManager() {
    return this.authService.isManager();
  }

  isBackOffice() {
    return this.authService.isBackOffice();
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  logOut() {
    this.authService.logOut();
    this.router.navigateByUrl('/home');
  }



}
